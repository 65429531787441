* {
    box-sizing: border-box;
}

body {
    padding: 0;
    margin: 0;
}

.metamask-btn {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #111;
}

.wallet-content {
    padding: 20px 0 0 0;
    text-align: center;
}

.app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #111;
    color: #fff;
}

.home-page {
    padding: 5px 0;
}

.header {
    padding: 1rem;
    background-color: #222;
    text-align: center;
}

.footer {
    padding: 1rem;
    background-color: #222;
    text-align: center;
    margin-top: auto;
}